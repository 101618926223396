<template>
    <div id="AccountGroupSystemId">
        <v-container fluid app>
            <ZnapTable ref="znapTable"
                :endpoint="endpoint"
                :tableName="tableName"
                :tableIcon="tableIcon"
                :tableDescription="tableDescription"
                :options="options"
                :tableRows="tableRows"
                :noData="noData"
                @setNoData="setNoData"
                :noDataMessage="noDataMessage"
                @setNoDataMessage="setNoDataMessage"
                :filterConditions="filterConditions"
                @setFilterConditions="setFilterConditions"
                @clearFilters="setFilterConditions"
                :loadTable="loadTable"
                @setLoadTable="setLoadTable"
                @clearNotRequiredOptionsItems="clearNotRequiredOptionsItems"
            >
                <!-- // Filters -->
                <!-- <template v-slot:filters>
                    <ZnapFilters ref="znapFilters"
                        :filterComponents="options"
                        :noData="noData"
                        @setSelection="populateFilterConditions"
                        :clearFilters="clearFilters"
                        @setClearFilters="setClearFilters"
                    >
                    </ZnapFilters>
                </template> -->
            </ZnapTable>
        </v-container>
    </div>
</template>

<script>
// import Vue from 'vue'
import ZnapTable from '@/components/znap/ZnapTable.vue'
// import ZnapFilters from '@/components/znap/Filters.vue'

export default {
    name:'AccountGroupSystemId',

    components: { ZnapTable },

    computed: {
        endpoint() {
            return [
                this.$ipAccount,
                'account-group-system-id',
                {
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_module',
                            operator: '=',
                            value: 14
                        }
                    ]
                }
            ]
        },
    },

    data: () => ({
        tableName: 'ID sistêmico para as contas gerenciais de investimentos',
        tableIcon: 'mdi-database-arrow-left',
        tableDescription: 'Forneça uma descrição para esta tabela.',
        tableRows: 100,

        options: [
            // {
            //     column: 'id_module',
            //     is: 'ComboBox',
            //     items: [],
            //     multiple: false,
            //     label: 'Módulo',
            //     required: false,
            //     filterable: false,
            //     endpoint: [ Vue.prototype.$ipSecurity, 'module' ]
            // },
        ],

        noDataMessage: '',
        noData: true,

        // Filters
        filterConditions: [],
        loadTable: false,
        clearFilters: false,
    }),

    methods: {
        populateFilterConditions(selectionArray, requiredFilter) {
            this.filterConditions = []

            if (requiredFilter) {
                this.filterConditions.push({
                    AndOr: 'AND',
                    column: selectionArray[0].column,
                    operator: '=',
                    value: selectionArray[0].id,
                    required: true
                })

                this.loadTable = true
            } else {
                if (selectionArray.length !== 0) {
                    selectionArray.forEach(selection => {
                        
                        let required = false
                        this.options.forEach(option => {    
                            if (option.required && selection.column === option.column) {
                                required = true
                            }
                        })

                        if (selection.id === undefined) {
                            this.filterConditions = []
                        } else {
                            if (selection.id.length > 1) {
                                this.filterConditions.push({
                                    AndOr: 'AND',
                                    column: selection.column,
                                    operator: 'IN',
                                    value: selection.id,
                                    required
                                })
                            } else {
                                this.filterConditions.push({
                                    AndOr: 'AND',
                                    column: selection.column,
                                    operator: '=',
                                    value: selection.id,
                                    required
                                })
                            }             
                        }
                    })
                }
            }
        },

        setNoDataMessage(message) {
            this.noDataMessage = message
        },

        setNoData(payload) {
            this.noData = payload
        },

        setFilterConditions(array) {
            this.filterConditions = array
            
            if (array.length === 0) {
                this.clearFilters = true
            }

            this.options.forEach(option => {
                if (option.required) {
                    this.loadTable = true
                } 
            })
        },

        setClearFilters(payload) {
            this.clearFilters = payload
        },

        clearNotRequiredOptionsItems() {
            this.options.forEach(option => {
                if (!option.required) {
                    option.items = []
                } 
            })
        },

        setLoadTable(payload) {
            this.loadTable = payload
        }
    },
}
</script>

<style>
</style>